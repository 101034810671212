<div class="container mt-3">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h2>Order List</h2>
    <button class="btn btn-primary" (click)="loadOrders()">Reload Orders</button>
  </div>

  <ngx-spinner name="orderListSpinner" type="ball-spin-clockwise"></ngx-spinner>

  <div *ngIf="orders.length > 0; else noOrders">
    <div class="order-header row g-3 text-white bg-dark">
      <div class="col">Order ID</div>
      <div class="col">Status</div>
      <div class="col">Owner</div>
      <div class="col">Total Price</div>
      <div class="col">Items Count</div>
      <div class="col">Actions</div>
    </div>

    <div *ngFor="let order of orders" class="order-item row g-3 text-white">
      <div class="col">
        {{ order.orderId }}
        <span class="d-inline-block m-2 text-white pointer" routerLinkActive="active"
              [routerLink]="['/orders/view/', order.orderId]">
          <fa-icon class="mx-2" [icon]="faEye"></fa-icon>
        </span>
      </div>
      <div class="col">
        <span class="badge bg-info text-dark">{{ order.status }}</span>
      </div>
      <div class="col">{{ order.owner }}</div>
      <div class="col">{{ order.totalPrice | pricePipe }}</div>
      <div class="col">{{ order.orderItems?.length || 0 }}</div>
      <div class="col">
        <button class="btn btn-danger btn-sm" (click)="removeOrder(order.orderId)">
          <fa-icon [icon]="faTrash"></fa-icon>
          Delete
        </button>
      </div>
    </div>
  </div>

  <ng-template #noOrders>
    <div class="alert alert-warning mt-3 text-center">
      No orders found.
    </div>
  </ng-template>
</div>
