import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppConstants} from './common/app-constants';
import {ThemeService} from './service/themes.service';
import {SharedImgService} from "./service/shared-img.service";
import {Subject} from "rxjs";
import {NgxPopperjsTriggers} from "ngx-popperjs";
import {AuthService} from "./service/auth.service";
import {Admin} from "./model/admin/admin";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {

  faUser = AppConstants.iconUser;
  faClock = AppConstants.iconClock;
  faEmail = AppConstants.iconEmail;
  darkTheme: string;
  value = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentAdmin: Admin;
  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;

  constructor(private sharedService: SharedImgService,
              private theme: ThemeService,
              private route: Router,
              private authService: AuthService) {
    this.authService.currentAdmin.subscribe(x => this.currentAdmin = x);
  }

  ngOnInit(): void {
    this.resolveThemeMarker();
    this.currentAdmin = this.authService.currentAdminValue;
  }

  logout(): void {
    this.authService.logout();
    this.route.navigate(['/login']);
  }

  switchTheme() {
    this.value = !this.value;
    if (this.theme.current === 'light') {
      this.theme.current = 'dark';
      this.darkTheme = 'Dark theme';
      this.sharedService.addLoginImgPath('/assets/images/user-white.png')
    } else {
      this.theme.current = 'light';
      this.darkTheme = 'Light theme';
      this.sharedService.addLoginImgPath('/assets/images/user-black.png')
    }
  }

  resolveThemeMarker() {
    if (this.theme.current === 'light') {
      this.darkTheme = 'Light theme';
      this.value = false;
      this.theme.current = 'light';
      this.sharedService.addLoginImgPath('/assets/images/user-black.png');
    } else {
      this.value = true;
      this.darkTheme = 'Dark theme';
      this.theme.current = 'dark';
      this.sharedService.addLoginImgPath('/assets/images/user-white.png');
    }
  }
}
