import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Product} from "../../model/product/product";
import {Category} from "../../model/category/category";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {CategoryService} from "../../service/category.service";
import {ProductService} from "../../service/product.service";


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  standalone: false
})
export class ProductListComponent implements OnInit, OnDestroy {

  products: Array<Product>;
  pages: Array<number> = new Array<number>(0);
  page = 0;
  categories: Category[];
  selectedCategory: Category = null;
  destroy$ = new Subject();
  name: string;

  faTrash = AppConstants.iconTrash;
  faEdit = AppConstants.iconEdit;
  faEye = AppConstants.iconEye;
  faBan = AppConstants.iconBan;
  faView = AppConstants.iconEye;
  errorDto = new ErrorDto();

  constructor(public categoryService: CategoryService,
              public productService: ProductService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.filterProducts(null);
    this.getCategories();
  }

  filterProducts(name?: string) {
    this.spinner.show('productsSpinner').then(r => r);
    this.productService.listProducts(this.page, this.errorDto, name, this.selectedCategory)
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe({
        next: productFilterResponseDto => {
          this.spinner.hide('productsSpinner')
            .then(() => {
              this.products = productFilterResponseDto.data;
              this.pages = new Array<number>(productFilterResponseDto.meta.totalPages);
              setTimeout(() => {
                this.errorDto = new ErrorDto();
              }, AppConstants.toastSuccessDelay);
            });
        },
        error: () => {
          this.spinner.hide('productsSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' failed', 'Product'));
        }
      });
  }

  removeProduct(product: Product) {
    this.spinner.show('productsSpinner').then(r => r);
    if (!confirm('Are you sure to delete product' + product.name + '?')) {
      this.spinner.hide('productsSpinner').then(r => r);
      return;
    }
    this.productService.deleteProduct(product, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('productsSpinner').then(
            () => {
              this.toastr.success(product.name + ' deleted', 'Product');
              this.products = this.products.filter(data => data.url !== product.url);
            });
        },
        error: () => {
          this.spinner.hide('productsSpinner').then(() =>
            this.toastr.error(this.errorDto.httpStatus + ' delete failed!', 'Product'));
        }
      });
  }

  getCategories() {
    this.spinner.show('categoriesSpinner').then(r => r);
    this.categoryService.getCategories(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categoriesResponse => {
          this.spinner.hide('categoriesSpinner').then(
            () => {
              this.categories = categoriesResponse.data;
              this.categories.push(new Category());
              setTimeout(() => {
                this.errorDto = new ErrorDto();
              }, AppConstants.toastSuccessDelay);
            });
        },
        error: () => {
          this.spinner.hide('categoriesSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' categories failed!', 'Category'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  selectCategory(event: Category) {
    !event.url ? this.selectedCategory = new Category() : this.selectedCategory = event;
    this.filterProducts();
  }

  setPage($event: number) {
    this.page = $event;
    this.filterProducts();
  }
}
