import {HttpHeaders} from '@angular/common/http';
import {
  faAddressCard,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBars,
  faBook,
  faCalendar,
  faCheck,
  faCircle,
  faClock,
  faDeleteLeft,
  faEdit,
  faEnvelope,
  faEye,
  faGear,
  faInfo,
  faInfoCircle,
  faLock,
  faMoneyCheckDollar,
  faPlus,
  faTrash,
  faUpload,
  faUser,
  faXmark
} from '@fortawesome/free-solid-svg-icons';

export class AppConstants {
  public static  readonly iconUser = faUser;
  public static readonly iconInfo = faInfoCircle;
  public static readonly iconLock = faLock;
  public static readonly iconArrowLeft = faArrowLeft;
  public static readonly iconArrowDown = faArrowDown;

  public static readonly iconArrowUp = faArrowUp;
  public static readonly iconUpload = faUpload;
  public static readonly iconTrash = faTrash;
  public static readonly iconEdit = faEdit;
  public static readonly iconEye = faEye;
  public static readonly iconCircle = faCircle;
  public static readonly iconBan = faBan;
  public static readonly iconPlus = faPlus;
  public static readonly iconBars = faBars;
  public static readonly iconAddressCard = faAddressCard;
  public static readonly iconClock = faClock;
  public static readonly iconMoney = faMoneyCheckDollar;
  public static readonly iconBook = faBook;
  public static readonly iconCalendar = faCalendar;
  public static readonly iconEmail = faEnvelope;
  public static readonly iconGear = faGear;
  public static readonly iconDelete = faDeleteLeft;
  public static readonly iconYes = faCheck;
  public static readonly iconNo = faXmark;
  public static readonly toastSuccessDelay = 2100;
  public static readonly iconInfof = faInfo;

  public static get baseUrl(): string {
    return 'https://waldashop.herokuapp.com';
    //return 'http://localhost:8080';
  }

  public static get acceptJson(): HttpHeaders {
    return new HttpHeaders()
      .set('Accept', 'application/json');
  }

  public static get contentTypeAndAcceptJson(): HttpHeaders {
    return this.acceptJson
      .set('Content-Type', 'application/json');
  }

  public static get emptyDefaultImage(): string {
    return '/assets/images/empty-product.png';
  }


}
