import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Product} from '../model/product/product';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ProductResponse} from '../model/product/product-response';
import {ProductFilterResponseDto} from '../model/pagination/product-filter-response-dto';
import {ProductImageData} from '../model/product/image/product-image-data';
import {AppConstants} from '../common/app-constants';
import {BaseService} from '../common/abstract/base-service';
import {ProductRequest} from '../model/product/product-request';
import {Category} from '../model/category/category';
import {ErrorDto} from '../model/error/error-dto';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {
  private productEndpoint = AppConstants.baseUrl + '/api/products';
  private productsFilterEndpoint = this.productEndpoint + '/filter';
  private productImageEndpoint = this.productEndpoint + '/img-upload/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listProducts(page: number, errorDto: ErrorDto, name?: string, category?: Category) {
    let paramsData = new HttpParams()
      .set('pageNumber', String(page))
      .set('pageSize', '10')
      .set('sortBy', 'NAME');

    if (name != null) {
      paramsData = paramsData.set('name', name);
    }
    if (category != null && category.url != null) {
      paramsData = paramsData.set('category', category.url);
    }
    if (page != null) {
      paramsData = paramsData.set('pageNumber', page);
    }

    return this.httpClient.get<ProductFilterResponseDto>(this.productsFilterEndpoint, {
      headers: AppConstants.acceptJson,
      params: paramsData
    })
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public createProduct(product: Product, errorDto: ErrorDto) {
    const body = JSON.stringify(new ProductRequest(product));
    return this.httpClient.post<ProductResponse>(this.productEndpoint, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public deleteProduct(product: Product, errorDto: ErrorDto) {
    const productUrl = this.addParamIntoUrl(this.productEndpoint, product.url);
    return this.httpClient.delete(productUrl, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public updateProduct(product: Product, productOriginalUrl: string, errorDto: ErrorDto) {
    const body = JSON.stringify(new ProductRequest(product));
    const productUrl = this.addParamIntoUrl(this.productEndpoint, productOriginalUrl);
    return this.httpClient.put<ProductResponse>(productUrl, body, {headers: AppConstants.contentTypeAndAcceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public getProduct(productUrl: string, errorDto: ErrorDto) {
    const productUrlEndpoint = this.addParamIntoUrl(this.productEndpoint, productUrl);
    return this.httpClient.get<ProductResponse>(productUrlEndpoint, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public getProductImage(productUrl: string, errorDto: ErrorDto) {
    return this.httpClient.get<ProductImageData>(this.productImageEndpoint + productUrl, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  public updateProductImage(file: File, productUrl: string, errorDto: ErrorDto) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<ProductImageData>(this.productImageEndpoint + productUrl, formData)
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }

}
