import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ActivatedRoute, Router} from '@angular/router';

import {AngularEditorConfig} from '@kolkov/angular-editor';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {BaseImageComponent} from "../../common/abstract/base-image-component";
import {Product} from "../../model/product/product";
import {Category} from "../../model/category/category";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {VariantDetail} from "../../model/variant-category/variant-detail";
import {CategoryService} from "../../service/category.service";
import {ProductService} from "../../service/product.service";
import {GenericConfigService} from "../../service/generic-config.service";
import {VariantCatalogService} from "../../service/variant-catalog.service";
import {VariantCatalog} from "../../model/variant-category/variant-catalog";


@Component({
  selector: 'app-product-edited',
  templateUrl: './product-upsert.component.html',
  standalone: false
})
export class ProductUpsertComponent extends BaseImageComponent implements OnInit, OnDestroy {
  selectedProductOriginalUrl: string;
  selectedProduct: Product = new Product();
  categories: Category[];
  selectedCategory: Category = new Category();
  addedCategories: string [] = [];
  currencies: string [] = ['CZK', 'EUR'];
  existingImage: any = null;
  imgContentType: string;
  htmlContent = '';
  faArrowLeft = AppConstants.iconArrowLeft;
  faPlus = AppConstants.iconPlus;
  faThrash = AppConstants.iconTrash;
  faBars = AppConstants.iconBars;
  faEye = AppConstants.iconEye;
  faAddressCard = AppConstants.iconAddressCard;
  errorDto = new ErrorDto();
  operation: string = "Create";
  destroy$: Subject<boolean> = new Subject<boolean>();
  catalogErrorDto = new ErrorDto();
  variants: VariantDetail[];

  constructor(protected categoryService: CategoryService,
              protected productService: ProductService,
              protected genericConfigService: GenericConfigService,
              protected route: Router,
              protected actRoute: ActivatedRoute,
              protected spinner: NgxSpinnerService,
              protected toastr: ToastrService,
              protected catalogService: VariantCatalogService) {
    super(spinner, toastr, route, productService, genericConfigService, true);
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'indent',
        'subscript',
        'superscript',
        'strikeThrough',
      ],
      [
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };
  selectedCatalog: VariantCatalog;
  catalogs: VariantCatalog[];
  headerName = "Product image";
  shown: boolean = false;

  ngOnInit(): void {

    this.actRoute.queryParamMap.subscribe(params => {
      this.selectedProductOriginalUrl = params.get('productUrl');
      if (!this.selectedProductOriginalUrl) {
        this.operation = "Create";
      } else {
        this.operation = "Update";
      }
      this.resolveLoading();
    });
  }

  private resolveLoading() {
    this.getCategories();
    this.getCatalogs();
    if (this.operation == "Create") {
      return;
    }
    this.getProduct();
    this.getImage();
  }

  public upsertProduct() {
    if (this.operation == "Create") {
      this.createProduct()
    } else {
      this.updateProduct();
    }
  }

  private getProduct() {
    this.spinner.show('productSpinnerEdited').then(r => r);
    return this.productService.getProduct(this.selectedProductOriginalUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productResponse => {
          this.spinner.hide('productSpinnerEdited').then(
            () => {
              this.selectedProduct = productResponse.data;
              this.addedCategories = this.selectedProduct.categoryUrls;
              this.htmlContent = this.selectedProduct.description;
              this.errorDto = new ErrorDto();
            });
        },
        error: () => {
          this.spinner.hide('productSpinnerEdited').then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' load product failed!', 'Product');
              this.categories = [];
              this.selectedCategory = new Category();
            });
        }
      });
  }

  private getCatalogs() {
    this.spinner.show('catalogSpinner').then(r => r);
    this.catalogService.listAll(this.catalogErrorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: catalogResponse => {
          this.spinner.hide('catalogSpinner').then(
            () => {
              this.catalogs = catalogResponse.data;
              this.selectedCatalog = this.catalogs[0];
            });
        },
        error: () => {
          this.spinner.hide('catalogSpinner').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load catalog failed!', 'Catalog'));
        }
      });
  }

  private getCategories() {
    this.spinner.show('categoriesSpinnerEdited').then(r => r);
    this.categoryService.getCategories(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categoriesResponse => {
          this.spinner.hide('categoriesSpinnerEdited').then(
            () => {
              this.categories = categoriesResponse.data;
              this.selectedCategory = this.categories[0];

            });
        },
        error: () => {
          this.spinner.hide('categoriesSpinnerEdited').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load category failed!', 'Category'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getImage() {
    this.spinner.show('productImageSpinnerEdited').then(r => r);
    this.productService.getProductImage(this.selectedProductOriginalUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productImageData => {
          this.spinner.hide('productImageSpinnerEdited').then(() => {
            if (productImageData.data.bytes == null) {
              this.existingImage = null;
              this.imgContentType = null;
            } else {
              this.existingImage = 'data:image/jpeg;base64,' + productImageData.data.bytes;
              this.imgContentType = productImageData.data.type;
            }
          });
        },
        error: () => {
          this.spinner.hide('productImageSpinnerEdited').then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load image failed!', 'Product'));
        }
      });
  }

  public selectImage(event: File) {
    this.selectedFile = event;
  }

  private updateProduct() {
    this.spinner.show('productImageSpinnerEdited').then(r => r);
    this.selectedProduct.categoryUrls = this.addedCategories;
    this.selectedProduct.description = this.htmlContent;

    if (this.selectedProduct?.description?.length < 12 && this.selectedProduct?.description?.length > 0) {
      this.errorDto.httpStatus = 400;
      this.errorDto.httpStatusMessage = 'Bad Request';
      this.errorDto.httpStatusMessage = 'Description length < 12';
      this.spinner.hide('productImageSpinnerEdited').then(r => r);
      return;
    }
    if (this.selectedProduct?.description?.length > 600 && this.selectedProduct.description?.length > 0) {
      this.errorDto.httpStatus = 400;
      this.errorDto.httpStatusMessage = 'Bad Request';
      this.errorDto.httpStatusMessage = 'Description length < 12';
      this.spinner.hide('productImageSpinnerEdited').then(r => r);
      return;
    }


    this.productService.updateProduct(this.selectedProduct, this.selectedProductOriginalUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productResponse => {
          this.spinner.hide('productImageSpinnerEdited').then(() => {
            this.htmlContent = '';
            this.selectedProduct = productResponse.data;
            this.selectedProductOriginalUrl = productResponse.data.url;
            this.upsertProductImage(this.selectedProductOriginalUrl, this.errorDto);
          });
        },
        error: () => {
          this.spinner.hide('productImageSpinnerEdited').then(
            () => this.toastr.error(this.selectedProductOriginalUrl + ' product update failed', 'Product'));
        }
      });
  }

  private createProduct() {
    this.selectedProduct.categoryUrls = this.addedCategories;
    this.selectedProduct.description = this.htmlContent;
    if (this.selectedProduct?.description?.length < 12 && this.selectedProduct?.description?.length > 0) {
      this.errorDto.httpStatus = 400;
      this.errorDto.httpStatusMessage = 'Bad Request';
      this.errorDto.httpStatusMessage = 'Description length < 12';
      this.spinner.hide('productImageSpinnerEdited').then(r => r);
      return;
    }
    if (this.selectedProduct?.description?.length > 600 && this.selectedProduct.description?.length > 0) {
      this.errorDto.httpStatus = 400;
      this.errorDto.httpStatusMessage = 'Bad Request';
      this.errorDto.httpStatusMessage = 'Description length < 12';
      this.spinner.hide('productImageSpinnerEdited').then(r => r);
      return;
    }

    this.spinner.show().then(r => r);
    this.productService.createProduct(this.selectedProduct, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: productResponse => {
          this.spinner.hide().then(() => this.toastr.success(productResponse.data.name, 'Product'));
          this.selectedProduct = productResponse.data;
          this.upsertProductImage(this.selectedProduct.url, this.errorDto);
        },
        error: err => {
          this.spinner.hide().then(() => this.toastr.error(this.selectedProduct.name + ' create failed', 'Product'));
        }
      });
  }

  selectCurrency(event: string) {
    this.selectedProduct.priceCurrency = event;
  }

  selectCategory(event: Category) {
    this.selectedCategory = event;
  }

  addCategory() {
    const category = this.addedCategories.find(url => url === this.selectedCategory.url);
    if (!category && this.selectedCategory?.url) {
      this.addedCategories.push(this.selectedCategory.url);
    }
  }

  removeCategories(catUrl: String) {
    this.addedCategories = this.addedCategories.filter(url => url !== catUrl)
  }

  show() {
    this.shown=!this.shown;
  }
}
