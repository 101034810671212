import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Admin} from "../../model/admin/admin";
import {Audit} from "../../model/audit/audit";
import {ErrorDto} from "../../model/error/error-dto";
import {AppConstants} from "../../common/app-constants";
import {Role} from "../../model/admin/role";
import {AccountService} from "../../service/account.service";
import {AuthService} from "../../service/auth.service";


@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss'],
  standalone: false
})
export class AccountEditComponent implements OnInit, OnDestroy {
  currentAdmin: Admin;
  originalUsername = '';
  selectedAccount: Admin = new Admin();
  audit: Audit = new Audit();
  errorDto: ErrorDto;
  faArrowLeft = AppConstants.iconArrowLeft;
  destroy$: Subject<boolean> = new Subject<boolean>();
  info = '';
  roles: string[];
  faUser = AppConstants.iconUser;
  faCalendar = AppConstants.iconCalendar;
  roleHelper = Role;

  constructor(private accountService: AccountService,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private route: Router,
              private actRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.roles = Object.keys(Role)
      .map(key => Role[key])
      .filter(value => typeof value === 'string' && value != Role[Role.OWNER]) as string[];
    this.currentAdmin = this.authService.currentAdminValue;
    this.errorDto = new ErrorDto();
    this.actRoute.paramMap.subscribe(params => {
      this.originalUsername = params.get('username');
      this.loadAccount(this.originalUsername);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updateAccount() {
    this.spinner.show('accountSpinner').then(r => r);
    this.accountService.editAccount(this.selectedAccount, this.originalUsername, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: adminResponse => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.success('Account updated 200', 'Accounts');
              this.selectedAccount = adminResponse.data;
              this.audit = adminResponse.audit;
              this.errorDto = new ErrorDto();
              this.info = 'Account updated';
              setTimeout(() => {
                this.route.navigate(['/accounts']);
              }, AppConstants.toastSuccessDelay);
            }
          );
        },
        error: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.toastr.error('Account update failed ' + this.errorDto.httpStatus, 'Accounts');
            });
        }
      });
  }

  loadAccount(username: String) {
    this.spinner.show('accountSpinner').then(r => r);
    this.accountService.getAccount(username, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: adminResponse => {
          this.spinner.hide('accountSpinner').then(
            () => this.toastr.info('account reloaded', 'Accounts'));
          this.selectedAccount = adminResponse.data;
          this.audit = adminResponse.audit;
          this.errorDto = new ErrorDto();
          if (this.selectedAccount.role.toString() == Role[Role.OWNER]) {
            this.roles.push(Role[Role.OWNER]);
          }
        },
        error: () => {
          this.spinner.hide('accountSpinner').then(
            () => {
              this.selectedAccount = new Admin();
              this.toastr.error('Unable to load account ' + this.errorDto.httpStatus, 'Accounts');
            });
        }
      });
  }

  selectRole(value:Role) {
    this.selectedAccount.role = value;
  }

  isOwnAccountOrOwnerRole() {
    return !this.isNotOwnAccount() || this.selectedAccount?.role?.toString() == Role[Role.OWNER];
  }

  isNotOwnAccount() {
    return this.currentAdmin.username != this.originalUsername;
  }

}
