import {Component, OnInit} from '@angular/core';
import {AuthService} from "../service/auth.service";
import {Admin} from "../model/admin/admin";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  standalone: false,
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent implements OnInit {
  currentAdmin: Admin;

  constructor(private authService: AuthService,
  ) {
    this.authService.currentAdmin.subscribe(x => this.currentAdmin = x);
  }

  ngOnInit(): void {
    this.currentAdmin = this.authService.currentAdminValue;
  }
}
