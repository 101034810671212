import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import {GenericConfigDataDto} from "../../model/generic-config/generic-config-data-dto";
import {ErrorDto} from "../../model/error/error-dto";
import {AppConstants} from "../../common/app-constants";
import {GenericConfigService} from "../../service/generic-config.service";

@Component({
  selector: 'app-generic-config-list',
  templateUrl: './generic-config-list.component.html',
  standalone: false
})
export class GenericConfigListComponent implements OnInit, OnDestroy {

  defaultModule = ModuleEnum.FRONTEND_ADMIN
  genericConfigs: GenericConfigDataDto[] = [];
  errorDto: ErrorDto = new ErrorDto();
  destroy$: Subject<boolean> = new Subject<boolean>();
  faEdit = AppConstants.iconEdit;
  faDelete = AppConstants.iconDelete;


  constructor(
    private genericConfigService: GenericConfigService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadConfigsFromServer(this.defaultModule);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private loadConfigsFromServer(module: ModuleEnum) {
    this.spinner.show().then(r => r);
    this.genericConfigService.getGenericConfigs(module, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: genericConfigResponse => {
            this.spinner.hide().then(
              () => {
                this.genericConfigs = genericConfigResponse.data;
                this.errorDto = new ErrorDto();
              });
          },
          error: () => {
            this.spinner.hide().then(
              () => this.toastr.error(this.errorDto.httpStatus + ' failed', 'Generic config'));
          }
        }
      );
  }

  filterChanged($event: ModuleEnum) {
    this.loadConfigsFromServer($event);
  }

  deleteGenericConfig(config: GenericConfigDataDto) {
    this.spinner.show().then(r => r);
    if (!confirm('Are you sure to delete product (' + config.key.module + config.key.name + ') ?')) {
      this.spinner.hide().then(r => r);
      return;
    }
  }
}
