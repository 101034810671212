<div class="container-fluid vh-100">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <button class="navbar-toggler mx-3 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHome"
            aria-controls="navbarHome" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-3" id="navbarHome">
      <a class="navbar-brand brand" href="#">
        <img class="img-banner" src="/assets/images/logo.png" alt="logo">Admin eshop</a>
      <ul class="navbar-nav me-auto">
        <li class="nav-item"><span class="nav-link"><span class=" me-2">{{ darkTheme }}</span>
        <ng-toggle
          (valueChange)="switchTheme()"
          [value]="value"
          [color]="{
          unchecked: 'blue',
          checked: 'darkorange'
          }"
        ></ng-toggle>
      </span>
        </li>
        <li class="nav-item" *ngIf="!currentAdmin">
          <a class="nav-link"
             routerLinkActive="active"
             routerLink="login">Login
          </a>
        </li>

        <li class="nav-item" *ngIf="currentAdmin"><a class="nav-link" (click)="logout()">Logout</a></li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item" *ngIf="currentAdmin"
            [popper]="popper1Content"
            [popperApplyClass]="'popper-content'"
            [popperShowOnStart]="false"
            [popperTrigger]="'hover'"
            [popperHideOnClickOutside]="true"
            [popperHideOnScroll]="true"
            [popperPlacement]="'bottom'">
          <a class="nav-link">
            <fa-icon [icon]="faUser"></fa-icon>&nbsp;{{ currentAdmin.username }}</a></li>
        <popper-content #popper1Content>
          <span class="mb-2 d-block"><fa-icon [icon]="faEmail"></fa-icon>&nbsp;&nbsp;{{ currentAdmin?.email }}</span>
          <span class="d-block"><fa-icon [icon]="faUser"></fa-icon>&nbsp;&nbsp;{{ currentAdmin?.role }}</span>
        </popper-content>
        <li class="nav-item" *ngIf="currentAdmin"
            [popperApplyClass]="'popper-content'"
            [popper]="'Time out'"
            [popperTrigger]="NgxPopperjsTriggers.click"
            [popperPlacement]="'bottom'"><a class="nav-link">
          <fa-icon [icon]="faClock"></fa-icon>&nbsp;{{ currentAdmin.expiration | date:'HH:mm' }}</a></li>
      </ul>
    </div>
  </nav>


  <div class="row h-100">
    <app-main-menu class="col-3 col-md-2"></app-main-menu>
    <main class="col bg-dark overflow-auto">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
