import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Category} from "../../model/category/category";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {CategoryService} from "../../service/category.service";
import {Audit} from "../../model/audit/audit";


@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  standalone: false
})
export class CategoryViewComponent implements OnInit, OnDestroy {
  selectedOriginalCategoryUrl: string;
  selectedCategory: Category = new Category();
  auditCategory: Audit = new Audit();
  faEdit = AppConstants.iconEdit;
  faThrash = AppConstants.iconTrash;
  faCalendar = AppConstants.iconCalendar;
  faUser = AppConstants.iconUser;
  faEye = AppConstants.iconEye;
  faArrowLeft = AppConstants.iconArrowLeft;
  errorDto = new ErrorDto();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private categoryService: CategoryService,
              private route: Router,
              private actRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.spinner.show().then(r => r);
    this.actRoute.paramMap.subscribe(params => {
      this.selectedOriginalCategoryUrl = params.get('categoryUrl');
    });
    this.categoryService.getCategory(this.selectedOriginalCategoryUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: categoryResponse => {
          this.spinner.hide().then(() => this.toastr.info('Category ' + this.selectedOriginalCategoryUrl + ' loaded', 'Category'));
          this.selectedCategory = categoryResponse.data;
          this.auditCategory = categoryResponse.audit;
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto + ' failed', 'Category'));
          this.selectedCategory = new Category();
          this.auditCategory = new Audit();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
