import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppConstants} from "../../common/app-constants";

@Component({
  selector: 'app-img-insert',
  styleUrls: ['./img-update.component.scss'],
  template: `
    <div class="content text-center mx-2">
      <h5>{{headerName}}</h5>
      <div class="row">
        <div class="col">
          <!-- 1st row actual image -->
          <img *ngIf=existingImage class="img-fluid" [src]="existingImage" alt="{{existingImageName}}">
          <img *ngIf="!existingImage && !selectedImage" class="img-fluid"
               [src]="emptyDefaultImage" alt="empty image">
          <img *ngIf="selectedImage && !existingImage" class="img-fluid"
               [src]="selectedImage" alt="empty image">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- 2nd row upload button -->
          <label for="file-upload" class="file-uploader">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<fa-icon [icon]="faUpload"></fa-icon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input id="file-upload" type="file" accept="image/*" (change)="selectFile($event)">

          <div *ngIf="invalidImage" class="alert alert-danger">
            {{invalidImage}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- 3nd row new image -->
          <div *ngIf="existingImage && revertImage">
            <h5> To be updated image</h5>
            <img class="img-responsive" [src]="selectedImage" alt="added new image">
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <div class="content" *ngIf="selectedFile">
            <span>{{selectedFile.name}}</span><br>
            <span>{{selectedFile.size / 1000}} kb</span><br>
            <details *ngIf="height">
              <summary class="p-2">{{height}} x {{width}}</summary>
              <code>height x width</code>
            </details>
            <div *ngIf="revertImage" class="col p-3">
              <button type="button" class="btn btn-primary" (click)="revert()">&nbsp;Revert</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  standalone: false
})
export class ImgUpdateComponent {
  private regex: RegExp = new RegExp('\\w*.(gif|jpe?g|tiff?|png|webp|bmp)$');
  private reader = new FileReader();
  emptyDefaultImage = AppConstants.emptyDefaultImage;
  faUpload = AppConstants.iconUpload;
  selectedFile: File;
  selectedImage: any = null;
  revertImage = false;
  width: number;
  height: number;
  invalidImage: string;
  @Input() existingImage: any;
  @Input() existingImageName: string;
  @Input() headerName: string;
  @Input() maxSize: number;
  @Input() maxDimension: number
  @Output() selectedImageEmitter: EventEmitter<File> = new EventEmitter();

   revert() {
    this.revertImage = false;
    this.selectedImage = null;
    this.selectedFile = null;
    this.invalidImage = null;
  }

   selectFile(event: Event) {
    console.log('Select file call');
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    console.log(fileList);
    if (!fileList) {
      console.log('No file selected');
      this.selectedFile = null;
      this.selectedImage = null;
      this.selectedImageEmitter.emit(null);
      return;
    }
    this.selectedFile = fileList.item(0);
    if (this.regex.test(this.selectedFile.name.toLowerCase())) {
      this.invalidImage = null;
    } else {
      this.invalidImage = 'Only image should be added';
      this.selectedFile = null;
      this.selectedImage = null;
      this.selectedImageEmitter.emit(this.selectedFile);
      return;
    }
    const selectedFileSize = this.selectedFile.size / 1000;
    if (selectedFileSize > this.maxSize) {
      this.invalidImage = 'Size image too large! Maximum: ' + this.maxSize;
      console.error(this.invalidImage, selectedFileSize);
      this.selectedImageEmitter.emit(null);
      return;
    }

    this.reader.readAsDataURL(this.selectedFile);
    this.reader.onload = (event: any) => {
      this.selectedImage = event.target.result;
      this.selectedImageEmitter.emit(this.selectedFile);
      this.revertImage = true;
      const img = new Image();
      img.src = this.selectedImage;
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        this.overlapScale('width', this.width);
        this.overlapScale('height', this.height);
      };
      img.onerror = () => {
        console.error('Img could not be read: ', event.target.error.code);
        this.selectedImageEmitter.emit(null);
      };
    };

    this.reader.onerror = (event: any) => {
      this.revertImage = false;
      console.error('File could not be read: ', event.target.error.code);
    };
  }

  private overlapScale(fieldName: string, scale: number) {
    if (scale > this.maxDimension) {
      console.error('Maximum image ' + fieldName + '!', this.maxDimension, scale);
      this.invalidImage = 'Image ' + fieldName + ' oversize! Maximum: ' + this.maxDimension;
      this.selectedFile = null;
      this.selectedImage = null;
      this.revertImage = false;
      this.selectedImageEmitter.emit(null);
    }
  }
}
