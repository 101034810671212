import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {AppConstants} from "../../common/app-constants";
import {ErrorDto} from "../../model/error/error-dto";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {WriteUsResponse} from "../../model/email/write-us-response";
import {WriteUsService} from "../../service/write-us.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-write-us-list',
  templateUrl: './write-us-list.component.html',
  styleUrls: ['./write-us-list.component.scss'],
  standalone: false
})
export class WriteUsListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  emailList = new Array<WriteUsResponse>();
  emailListFiltered = new Array<WriteUsResponse>();
  faEdit = AppConstants.iconEdit;
  faDelete = AppConstants.iconDelete;
  faYes = AppConstants.iconYes;
  faNo = AppConstants.iconNo;
  errorDto: ErrorDto = new ErrorDto();
  replied = false;

  constructor(private writeUs: WriteUsService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.getEmails();
  }

  private getEmails() {
    this.spinner.show().then(r => r);
    this.writeUs.loadEmailsWriteUs(this.errorDto, 0, 20, null, null)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide().then(
            () => this.toastr.info(response.data.length + ' emails reloaded', 'Emails'));
          this.emailList = response.data;
          this.emailListFiltered = this.emailList.filter(data => data.replied == false);
        },
        error: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' emails reload failed', 'Emails');
            });
        }
      });
  }

  resolveIconBasedOnReplied(replied: boolean) {
    return (replied) ? this.faYes : this.faNo;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  filter() {
    this.replied = !this.replied;
    if (this.replied == false) {
      this.emailListFiltered = this.emailList.filter(data => data.replied == false)
    } else {
      this.emailListFiltered = this.emailList;
    }
  }
}
