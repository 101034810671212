import {Pipe, PipeTransform} from '@angular/core';
import {MoneyDto} from "../model/order/order-item-response-dto";

@Pipe({
  name: 'pricePipe',
  standalone: false
})
export class PricePipe implements PipeTransform {

  transform(money: MoneyDto, ...args: unknown[]): unknown {
    return `${money.amount} ${money.currencyCode}`;
  }
}
