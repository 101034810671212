import {Component, Input} from '@angular/core';
import {AppConstants} from "../../common/app-constants";
import {Audit} from "../../model/audit/audit";

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  standalone: false
})
export class AuditComponent {
  faCalendar = AppConstants.iconCalendar;
  faUser = AppConstants.iconUser;
  @Input() audit: Audit = new Audit();
}
