<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-12 align-self-center">
      <div class="box-part text-center">
        <!-- Title -->
        <h3 class="text-orange mb-3">Accounts</h3>

        <!-- Table Header -->
        <div class="row text-center d-none d-sm-flex">
          <div class="col-sm-4">
            <span class="text-darkorange">Email</span>
          </div>
          <div class="col-sm-2">
            <span class="text-darkorange">Role</span>
          </div>
          <div class="col-sm-2" [ngClass]="{'col-sm-3': !showDelete()}">
            <span class="text-darkorange">Status</span>
          </div>
          <div class="col-sm-1">
            <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
          </div>
          <div *ngIf="showDelete()" class="col-sm-1">
            <fa-icon class="text-darkorange" [icon]="faDelete"></fa-icon>
          </div>
        </div>

        <!-- Spinner -->
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-spin-clockwise">
        </ngx-spinner>

        <!-- Admin List -->
        <div class="row text-center text-sm-start" *ngFor="let admin of adminList">
          <div class="input-group link">
            <!-- Username -->
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-sm-none text-darkorange">Username:</span>
              <span class="text-white">{{ admin.username }}</span>
            </div>

            <!-- Email -->
            <div class="col-12 col-sm-4 border-bottom">
              <span class="d-sm-none text-darkorange">Email:</span>
              <span class="text-white">{{ admin.email }}</span>
            </div>

            <!-- Role -->
            <div class="col-12 col-sm-2 border-bottom">
              <span class="d-sm-none text-darkorange">Role:</span>
              <span class="text-white">{{ admin.role }}</span>
            </div>

            <!-- Status -->
            <div class="col-12 col-sm-2 border-bottom" [ngClass]="{'col-sm-3': !showDelete()}">
              <span class="d-sm-none text-darkorange">Status:</span>
              <span class="text-white">{{ admin.status }}</span>
            </div>

            <!-- Edit Button -->
            <div class="col-12 col-sm-1 border-bottom text-center">
              <button class="btn btn-primary btn-sm" [routerLink]="['/accounts/edit/', admin.username]">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>

            <!-- Delete Button -->
            <div *ngIf="showDelete()" class="col-12 col-sm-1 border-bottom text-center">
              <button (click)="deleteAccount(admin.username)" class="btn btn-danger btn-sm">
                <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- Info and Error Components -->
        <app-info [info]="info"></app-info>
        <app-error [errorDto]="errorDto"></app-error>

        <!-- Create Account Button -->
        <button class="btn btn-primary mt-3" routerLink="/accounts/create">
          Create account
        </button>
      </div>
    </div>
  </div>
</div>
