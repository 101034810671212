<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-sm-12 align-self-center">
      <div class="box-part text-center">
        <h3 class="text-orange mb-3">Products</h3>
        <div>
          <div class="input-group my-3">
            <input class="form-control" name="q" type="text" placeholder="Search by name"
                   [ngModel]="name" (ngModelChange)="filterProducts($event)">

            <div class="input-group-btn">
              <select class="form-control" *ngIf="categories" [ngModel]="selectedCategory"
                      (ngModelChange)="selectCategory($event)">
                <option [ngValue]="category" *ngFor="let category of categories">
                  {{category.url}}
                </option>
              </select>
            </div>
          </div>

          <div class="row link text-center text-md-start">
            <div class="input-group d-none d-sm-inline-flex">
              <div class="col-12 col-sm-2">
                <span class="text-darkorange text-center">Name</span>
              </div>
              <div class="col-12 col-sm-1">
                <span class="text-darkorange">Price</span>
              </div>
              <div class="col-12 col-sm-1">
                <span class="text-darkorange">Currency</span>
              </div>
              <div class="col-12 col-sm-2">
                <span class="text-darkorange">Url</span>
              </div>
              <div class="col-12 col-sm-2">
                <span class="text-darkorange">Category url</span>
              </div>
              <div class="col-12 col-sm-1 text-center">
                <span class="text-darkorange"><fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon></span>
              </div>
              <div class="col-12 col-sm-1 text-center">
                <span class="text-darkorange"><fa-icon class="text-darkorange" [icon]="faTrash"></fa-icon></span>
              </div>
            </div>
          </div>

          <ngx-spinner name="categoriesSpinner"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>
          <ngx-spinner name="productsSpinner"
                       bdColor="rgba(51,51,51,0.8)"
                       size="medium"
                       color="#fff"
                       type="ball-spin-clockwise">
          </ngx-spinner>

          <div class="row link text-center text-md-start" *ngFor="let product of products">
            <div class="input-group border-bottom py-2">
              <div class="col-12 col-sm-2">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Name"></span>
                <span class="pointer d-inline-block m-2 text-white text-break" routerLinkActive="active"
                      [routerLink]="['/products/view/', product.url]"><fa-icon [icon]="faView" class="me-2"></fa-icon>{{product.name}}</span>
              </div>
              <div class="col-12 col-sm-1">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Price"></span>
                <span class="d-inline-block m-2 text-white">{{product.priceAmount}}</span>
              </div>
              <div class="col-12 col-sm-1">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Currency"></span>
                <span class="d-inline-block m-2 text-white">{{product.priceCurrency}}</span>
              </div>
              <div class="col-12 col-sm-2">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Url"></span>
                <span class="d-inline-block m-2 text-white">{{product.url}}</span>
              </div>
              <div class="col-12 col-sm-2">
                <span class="d-inline-block m-2 text-darkorange d-sm-none" data-label="Category url"></span>
                <span class="d-inline-block m-2 text-white">{{ product.categoryUrls }}</span>
              </div>
              <div class="col-12 col-sm-1 text-center my-auto">
                <button class="btn btn-primary" routerLinkActive="active"
                        [routerLink]="['/products/upsert']" [queryParams]=" { productUrl:product.url }">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
              </div>
              <div class="col-12 col-sm-1 text-center my-auto">
                <button (click)="removeProduct(product)" class="btn btn-danger"
                        routerLinkActive="active">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>

          <app-error [errorDto]="errorDto"></app-error>
          <app-pagination [pages]="pages" (pageChangeEmitter)="setPage($event)"></app-pagination>

          <button class="btn btn-primary" routerLinkActive="active" routerLink="/products/upsert">Create product
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
