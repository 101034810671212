<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-spin-clockwise">
</ngx-spinner>

<div class="box">
  <div class="row">
    <div class="col-10">
      <div class="box-part">
        <h2>Order detail (id = {{ actualOrder.orderId }})</h2>
        <div class="content col-6  text-start">
          <div class="row">
            <div class="col-3">Total price</div>
            <div class="col-3">{{ actualOrder.totalPrice | pricePipe }}</div>
          </div>
          <div class="row">
            <div class="col-3">Status</div>
            <div class="col-3">{{ actualOrder.status }}</div>
          </div>
          <div class="row">
            <div class="col-3">Payment type</div>
            <div class="col-3">{{ actualOrder.paymentType }}</div>
          </div>
          <div class="row">
            <div class="col-3">Note</div>
            <div class="col-3">{{ actualOrder.note }}</div>
          </div>
          <div class="row">
            <div class="col-3">Owner</div>
            <div class="col-3">{{ actualOrder.owner }}</div>
          </div>
        </div>

        <div class="content col-6 text-start">
          <h2>Order Items</h2>
          <div class="row">
            <div class="col-4">Name</div>
            <div class="col-3">Count</div>
            <div class="col-3">Price Total</div>
          </div>
          <div *ngFor="let orderItem of actualOrder.orderItems;" class="text-start row">
            <div class="col-4">
                <span class="pointer d-inline-block text-white text-break"
                      routerLinkActive="active"
                      [routerLink]="['/products/view/', orderItem.productUrl]"><fa-icon [icon]="faView"
                                                                                        class="me-2"></fa-icon>
                  {{ orderItem.name }}
                </span>
            </div>
            <div class="col-3">{{ orderItem.count }} ks</div>
            <div class="col-3">{{ orderItem.price | pricePipe }}</div>
          </div>
        </div>

        <app-address-view type="Billing" [address]="actualOrder.billingAddress"></app-address-view>
        <app-address-view type="Shipping" [address]="actualOrder.shippingAddress"></app-address-view>
<!--        <app-audit [audit]="actualAudit"></app-audit>-->

        <app-error [errorDto]="errorDto"></app-error>
        <button type="button" class="btn btn-danger" appBackButton>
          <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>
