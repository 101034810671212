import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../common/app-constants';
import {ErrorDto} from '../../model/error/error-dto';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {BaseService} from '../../common/abstract/base-service';
import {WriteUsResponse} from '../../model/email/write-us-response';
import {takeUntil} from 'rxjs/operators';
import {WriteUsService} from '../../service/write-us.service';
import {SharedImgService} from '../../service/shared-img.service';
import {Email} from '../../model/email/email';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-write-us',
  templateUrl: './write-us.component.html',
  styleUrls: ['./write-us.component.scss'],
  standalone: false
})
export class WriteUsComponent extends BaseService implements OnInit, OnDestroy {
  emailAddress = '';
  info = '';
  imgLoginPath = '';
  emails = new Array<WriteUsResponse>;
  htmlContent = '';
  faArrowLeft = AppConstants.iconArrowLeft;
  faThrash = AppConstants.iconTrash;
  faEye = AppConstants.iconEye;
  errorDto = new ErrorDto();
  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(protected actRoute: ActivatedRoute,
              protected sharedService: SharedImgService,
              protected writeUsService: WriteUsService,
              protected spinner: NgxSpinnerService,
              protected toastr: ToastrService) {
    super();
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'indent',
        'subscript',
        'superscript',
        'strikeThrough',
      ],
      [
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.emailAddress = params.get('email');
    });
    this.sharedService.currentImgPath.subscribe(data => this.imgLoginPath = data);
    this.getEmails();
  }

  private getEmails() {
    this.spinner.show().then(r => r);
    this.writeUsService.loadEmailsWriteUs(this.errorDto, 0, 20, this.emailAddress, null)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          setTimeout(() => {
            this.info = null;
          }, AppConstants.toastSuccessDelay);
          this.spinner.hide().then(() => this.emails = response.data);

        },
        error: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' emails reload failed', 'Emails');
            });
        }
      });
  }

  public hideAnswer() {
    return this.emails.filter(data => data.replied == true).length == this.emails.length;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  response(form: NgForm) {
    this.spinner.show().then(r => r);
    this.writeUsService.responseToEmail(this.errorDto, new Email(this.htmlContent, this.emailAddress))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(() => {
            form.resetForm();
            this.toastr.success('Odesláno', 'Emails');
            this.info = 'Email odeslán';
            setTimeout(() => {
              this.getEmails();
            }, AppConstants.toastSuccessDelay);
          });
        },
        error: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.error(this.errorDto.httpStatus + ' emails send failed', 'Emails');
            });
        }
      });
  }
}
