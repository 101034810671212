<div class="box">
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-12 align-self-center">
      <div class="box-part text-center">
        <!-- Title -->
        <h3 class="text-orange mb-3">Variant Catalog</h3>

        <!-- Header Row -->
        <div class="row">
          <div class="input-group d-none d-sm-flex text-center">
            <div class="col-sm-4">
              <span class="text-darkorange">Param Name</span>
            </div>
            <div class="col-sm-4">
              <span class="text-darkorange">Name</span>
            </div>
            <div class="col-sm-2">
              <span class="text-darkorange">Variants [ks]</span>
            </div>
            <div class="col-sm-1">
              <fa-icon class="text-darkorange" [icon]="faEdit"></fa-icon>
            </div>
            <div class="col-sm-1">
              <fa-icon class="text-darkorange" [icon]="faDelete"></fa-icon>
            </div>
          </div>
        </div>

        <!-- Spinner -->
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-spin-clockwise"
        ></ngx-spinner>

        <!-- Variant Categories -->
        <div
          class="row text-center text-sm-center"
          *ngFor="let variantCategory of variantCategories"
        >
          <div class="input-group link">
            <div class="col-sm-4 border-bottom">
              <span
                class="d-sm-none text-darkorange"
                data-label="Param Name"
              ></span>
              <span class="text-white">{{ variantCategory.paramName }}</span>
            </div>
            <div class="col-sm-4 border-bottom">
              <span class="d-sm-none text-darkorange" data-label="Name"></span>
              <span class="text-white">{{ variantCategory.name }}</span>
            </div>
            <div class="col-sm-2 border-bottom">
              <span
                class="d-sm-none text-darkorange"
                data-label="Variants"
              ></span>
              <span class="text-white">{{ variantCategory.variants?.length }}</span>
            </div>
            <div class="col-sm-1 border-bottom text-center">
              <button
                class="btn btn-primary btn-sm"
                [routerLink]="['/variant-catalogs/', variantCategory.paramName]"
                routerLinkActive="active"
              >
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
            </div>
            <div class="col-sm-1 border-bottom text-center">
              <button
                class="btn btn-danger btn-sm"
                (click)="deleteVariantCatalog(variantCategory)"
                routerLinkActive="active"
              >
                <fa-icon [icon]="faDelete"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- Error and Create Button -->
        <app-error [errorDto]="errorDto"></app-error>
        <button
          class="btn btn-primary mt-3"
          [routerLink]="['/variant-catalogs/create']"
          routerLinkActive="active"
        >
          Create Catalog
        </button>
      </div>
    </div>
  </div>
</div>
