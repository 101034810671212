<nav class="nav flex-column">
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/categories'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-list-task"></i>
    </span>
    <span class="description ms-2">Categories</span>
  </a>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/variant-catalogs'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-check2-square"></i>
    </span>
    <span class="description ms-2">Variant catalogs</span>
  </a>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/products'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-box"></i>
    </span>
    <span class="description ms-2">Products</span>
  </a>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/accounts'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-person"></i>
    </span>
    <span class="description ms-2">Accounts</span>
  </a>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/generic-configs'"
     *ngIf="currentAdmin"
     data-bs-toggle="collapse" data-bs-target="#submenu" aria-expanded="false" aria-controls="submenu"
  >
    <span class="icon">
      <i class="bi bi-gear-fill"></i>
    </span>
    <span class="description ms-2">Configuration <i class="bi bi-caret-down-fill"></i></span>
  </a>
  <div class="sub-menu collapse" id="submenu">
    <a class="nav-link ms-2"
       routerLinkActive="active"
       [routerLink]="'/img'"
       *ngIf="currentAdmin">
      <span class="icon">
        <i class="bi bi-image"></i>
      </span>
      <span class="description ms-2">Image</span>
    </a>
    <a class="nav-link ms-2"
       routerLinkActive="active"
       [routerLink]="'/qr-code'"
       *ngIf="currentAdmin">
      <span class="icon">
        <i class="bi bi-qr-code"></i>
      </span>
      <span class="description ms-2">Qr Code</span>
    </a>
    <a class="nav-link ms-2"
       routerLinkActive="active"
       [routerLink]="'/bank'"
       *ngIf="currentAdmin">
      <span class="icon">
        <i class="bi bi-bank"></i>
      </span>
      <span class="description ms-2">Bank</span>
    </a>
    <a class="nav-link ms-2"
       routerLinkActive="active"
       [routerLink]="'/advertisement'"
       *ngIf="currentAdmin">
      <span class="icon">
        <i class="bi bi-bank"></i>
      </span>
      <span class="description ms-2">Advertisement</span>
    </a>
  </div>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/orders'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-basket"></i>
    </span>
    <span class="description ms-2">Orders</span>
  </a>
  <a class="nav-link"
     routerLinkActive="active"
     [routerLink]="'/graph/home'"
     *ngIf="currentAdmin">
    <span class="icon">
      <i class="bi bi-graph-down"></i>
    </span>
    <span class="description ms-2">Graphs</span>
  </a>
</nav>
