import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from '../../service/order.service';
import {ErrorDto} from '../../model/error/error-dto';
import {Subject} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {faEdit, faEye, faTrash} from '@fortawesome/free-solid-svg-icons';
import {OrderDetailOutputDto} from '../../model/order/order-detail-output-dto';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../common/app-constants';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  standalone: false
})
export class OrderListComponent implements OnInit, OnDestroy {

  protected readonly faTrash = faTrash;
  protected readonly faEdit = faEdit;
  protected readonly faEye = faEye;
  orders: OrderDetailOutputDto[] = [];
  errorDto: ErrorDto = new ErrorDto();
  info: string | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.loadOrders();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadOrders(): void {
    this.spinner.show('orderListSpinner');
    this.orderService.listOrders(0, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.orders = response.data;
          this.spinner.hide('orderListSpinner').then(() => {
            this.toastr.info(`${response.data.length} orders reloaded`, 'Order List');
          });
        },
        error: () => {
          this.orders = [];
          this.spinner.hide('orderListSpinner').then(() => {
            this.toastr.error(`${this.errorDto.httpStatus} - Failed to load orders`, 'Order List');
          });
        }
      });
  }

  removeOrder(orderId: number): void {
    const confirmation = confirm(`Are you sure you want to delete order with ID ${orderId}?`);
    if (!confirmation) {
      return;
    }

    this.spinner.show();
    this.orderService.deleteOrder(orderId.toString(), this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.info = `Order ID ${orderId} deleted successfully.`;
          this.spinner.hide().then(() => {
            this.toastr.success(`Order ID ${orderId} deleted successfully`, 'Order List');
            setTimeout(() => (this.info = null), AppConstants.toastSuccessDelay);
            this.loadOrders();
          });
        },
        error: () => {
          this.spinner.hide().then(() => {
            this.toastr.error(`${this.errorDto.httpStatus} - Failed to delete order`, 'Order List');
          });
        }
      });
  }
}
