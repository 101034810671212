import {Component, OnDestroy, OnInit} from '@angular/core';
import {VariantCatalog} from "../model/variant-category/variant-catalog";
import {AppConstants} from "../common/app-constants";
import {Subject} from "rxjs";
import {ErrorDto} from "../model/error/error-dto";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {VariantCatalogService} from "../service/variant-catalog.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-variant-catalog-list',
  templateUrl: './variant-catalog-list.component.html',
  standalone: false
})
export class VariantCatalogListComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  variantCategories: VariantCatalog[];
  faEdit = AppConstants.iconEdit;
  faDelete = AppConstants.iconDelete;
  errorDto: ErrorDto = new ErrorDto();

  constructor(private variantCatalogService: VariantCatalogService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loadVariantCatalog();
  }

  private loadVariantCatalog() {
    this.spinner.show('Variant Catalog').then(r => r);
    this.variantCatalogService.listAll(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: catalogVariants => {
          this.spinner.hide('Variant Catalog').then(
            () => this.toastr.info(catalogVariants.data?.length + 'reloaded', 'VariantCatalogSpinner'));
          this.variantCategories = catalogVariants.data;
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error(this.errorDto?.httpStatus + ' category variants failed', 'VariantCatalogSpinner');
              this.variantCategories = [];
            });
        }
      });
  }

  deleteVariantCatalog(variantCategory: VariantCatalog) {
    if (!confirm('Are you sure to delete catalog?' + variantCategory.paramName + '?')) {
      return;
    }
    this.spinner.show('Variant Catalog').then(r => r);
    this.variantCatalogService.deleteVariantCatalog(variantCategory, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.loadVariantCatalog();
              this.toastr.info(variantCategory.paramName + ' deleted', 'VariantCatalogSpinner')
            });
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.toastr.error(this.errorDto?.httpStatus + ' delete failed', 'VariantCatalogSpinner');
            });
        }
      });
  }
}
