import {Component, Input, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import ConfigTypeEnum = GenericConfigDataDto.ConfigTypeEnum;
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import {GenericConfigDataDto} from "../../model/generic-config/generic-config-data-dto";
import {ErrorDto} from "../../model/error/error-dto";
import {GenericConfigService} from "../../service/generic-config.service";
import {AppConstants} from "../../common/app-constants";

@Component({
  selector: 'app-generic-config-edit-detail',
  templateUrl: './generic-config-edit-detail.component.html',
  standalone: false
})
export class GenericConfigEditDetailComponent implements OnInit {

  @Input()
  isCreate: boolean = false;
  @Input()
  module: ModuleEnum = null;
  @Input()
  key: string = null;

  availableModules: string[] = Object.values(ModuleEnum);
  availableConfigTypes: string[] = Object.values(ConfigTypeEnum);
  destroy$ = new Subject();
  errorDto = new ErrorDto();
  genericConfigRequest: GenericConfigDataDto = this.initGenericConfigDefault();

  private initGenericConfigDefault(): GenericConfigDataDto {
    return {
      key: {name: "PREDEFINNED_KEY", module: ModuleEnum.FRONTEND_ADMIN},
      type: ConfigTypeEnum.SHORTSTRING,
      value: "..."
    }
  }

  constructor(private genericConfigService: GenericConfigService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private route: Router,
              private actRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.isCreate) {
      return;
    } else {
      this.actRoute.paramMap.subscribe(params => {
        this.genericConfigRequest.key.name = params.get('key');
        this.genericConfigRequest.key.module = ModuleEnum[params.get('module')];
      });
    }
    this.loadConfigBy();
  }

  private loadConfigBy() {
    this.spinner.show().then(r => r);
    this.genericConfigService.getGenericConfigBy(this.genericConfigRequest.key.module, this.genericConfigRequest.key.name, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: genericConfigResponse => {
            this.spinner.hide().then(() => this.toastr.info(genericConfigResponse.data.key + ' generic configs reloaded', 'Generic config'));
            this.genericConfigRequest = genericConfigResponse.data;
          },
          error: () => {
            this.spinner.hide().then(() => this.toastr.error('Load failed' + this.errorDto.httpStatus, 'Generic config'));
          }
        }
      );
  }


  genericConfigSaveOperation() {
    if (this.isCreate) {
      this.createGenericConfig();
    } else {
      this.updateGenericConfig();
    }

  }

  private updateGenericConfig() {
    this.genericConfigService.putGenericConfig(
      this.genericConfigRequest.key.module,
      this.genericConfigRequest.key.name,
      this.genericConfigRequest.value, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.spinner.hide().then(
            () => {
              this.toastr.success('Config updated', 'Generic config');
              this.errorDto = new ErrorDto();
              setTimeout(() => {
                this.route.navigate(['/generic-configs']);
              }, AppConstants.toastSuccessDelay);
            });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' update failed', 'Generic config'));
        }
      });
  }

  private createGenericConfig() {
    this.genericConfigService.postGenericConfig(this.genericConfigRequest, this.errorDto)
      .subscribe({
          next: genericConfigResponse => {
            this.errorDto = new ErrorDto();
            this.spinner.hide().then(() => this.toastr.info(genericConfigResponse.data.value + ' created', 'Generic config'));
            setTimeout(() => {
              this.route.navigate(['/generic-configs']);
            }, AppConstants.toastSuccessDelay);
          },
          error: () => {
            this.spinner.hide().then(() => this.toastr.error(' Generic config post failed' + this.errorDto.httpStatus, 'Generic config'));
          }
        }
      );
  }

  getAction() {
    return this.isCreate ? "CREATE" : "UPDATE";
  }
}
