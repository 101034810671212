import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';

import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';

import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";
import {AppConstants} from "../../common/app-constants";
import {BankConfigurationDto} from "../../model/configuration/bank-configuration-dto";
import {GenericConfigDataDto} from "../../model/generic-config/generic-config-data-dto";
import {ErrorDto} from "../../model/error/error-dto";
import {GenericConfigService} from "../../service/generic-config.service";

declare var bootstrap: any;

@Component({
  selector: 'app-bank-configuration',
  templateUrl: './bank-configuration.component.html',
  standalone: false
})
export class BankConfigurationComponent implements OnInit, OnDestroy {

  private readonly ESHOP_BANK_IBAN = "BANK_IBAN";
  faArrowLeft = AppConstants.iconArrowLeft;
  faUser = AppConstants.iconUser;
  destroy$: Subject<boolean> = new Subject<boolean>();
  bankConfigurationDto: BankConfigurationDto = new BankConfigurationDto(null);
  genericData: GenericConfigDataDto;
  errorDto = new ErrorDto();

  constructor(private genericConfigService: GenericConfigService,
              private route: Router,
              private actRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    // Bootstrap tooltip initialization
    [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
    this.spinner.show().then(r => r);
    this.genericConfigService.getGenericConfigBy(ModuleEnum.FRONTEND_ADMIN, this.ESHOP_BANK_IBAN, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: bankConfig => {
          this.spinner.hide().then(() => {
            this.genericData = bankConfig.data;
            this.bankConfigurationDto = new BankConfigurationDto(this.genericData.value)
            this.errorDto = new ErrorDto();
          });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' load failed', 'Bank Config'));
        }
      });
  }

  upsertIban() {
    this.spinner.show().then(r => r);
    this.genericData.value = this.bankConfigurationDto.iban;
    this.genericConfigService.putGenericConfig(ModuleEnum.FRONTEND_ADMIN, this.ESHOP_BANK_IBAN, this.genericData.value, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: bankConfig => {
          this.spinner.hide().then(
            () => {
              this.toastr.success('Bank config updated', 'Bank Config');
              this.bankConfigurationDto = new BankConfigurationDto(bankConfig.data.value);
              this.errorDto = new ErrorDto();
            });
        },
        error: () => {
          this.spinner.hide().then(
            () => this.toastr.error(this.errorDto.httpStatus + ' update failed', 'Bank Config'));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
