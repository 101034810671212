import {Component, EventEmitter, Input, Output} from '@angular/core';

import ModuleEnum = GenericConfigKeyDto.ModuleEnum;
import {GenericConfigKeyDto} from "../../model/generic-config/generic-config-key-dto";

@Component({
  selector: 'app-generic-config-list-filter',
  templateUrl: './generic-config-list-filter.component.html',
  standalone: false
})
export class GenericConfigListFilterComponent {

  @Input()
  selectedModule: ModuleEnum = null;
  availableModules = Object.values(ModuleEnum);

  @Output()
  selectedModuleEvent: EventEmitter<ModuleEnum> = new EventEmitter<GenericConfigKeyDto.ModuleEnum>()

  fireSelectChangedEvent() {
    console.log("Fired event" + this.selectedModule);
    this.selectedModuleEvent.emit(this.selectedModule);
  }
}
